$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';
@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

@media (max-width: 992px) {
    .clase-especial {
    display: none !important;
    }
}
@media (min-width: 992px) {
    .clase-min-especial {
    display: none !important;
    }
}
